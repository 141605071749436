/**
 * Combined by jsDelivr.
 * Original files:
 * - /npm/jquery@2.2.4/dist/jquery.min.js
 * - /npm/foundation-sites@6.7.5/dist/js/foundation.min.js
 * - /npm/what-input@5.2.3/dist/what-input.min.js
 * - /npm/plyr@3.5.6/dist/plyr.min.js
 * - /npm/plyr@3.5.6/dist/plyr.polyfilled.min.js
 *
 * Do NOT use SRI with dynamically generated files! More information: https://www.jsdelivr.com/using-sri-with-dynamic-files
 */
